import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Aside } from "components/blog/aside"
import { Breadcrumb } from "components/breadcrumb"
import { DefaultHeader } from "components/headers/default-header"

const HowTwitterInstantWinWorks = ({ location }) => {
  const title = "Twitterインスタントウィンの仕組みを説明します"
  const list = [
    { title: "よくある質問", link: "/qa" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle={`FAQ | ${title}`}
          pagedesc="キャンペーン期間中、プログラムが指定したツイートのデータを収集し続けています。収集したデータはCSVでの出力が可能で、時間帯別の参加者数・参加者のフォロワー分布などのデータを閲覧できます。"
          pagepath={location.pathname}
        />
        <Breadcrumb title="よくある質問" items={list} />
        <div className="qa-page l-content l-container">
          <main className="l-main-content">
            <h1 className="qa-page__title">
              Twitterインスタントウィンの仕組みを教えてください。
            </h1>
            <p className="qa-page__answer">
              キャンペーン期間中は、Twitterと連携されたPARKLoTのシステムが、指定したツイートをリツイートしたユーザのデータを収集しています。
              <br />
              リツイートしたユーザーデータを取得した後、プログラムが事前に設定した抽選確率に応じて自動的に抽選します。抽選結果はTwitterのAPIを利用してダイレクトメッセージで送信されます。
            </p>
            <p className="qa-page__text">
              当社を含め、Twitterの拡張機能(インスタントウィン、自動DM、ハッシュタグ収集など)を提供している事業者は、Twitter社が提供しているAPIを利用してインスタントウィンのサービスを提供しております。
              (
              <a
                href="https://help.twitter.com/ja/rules-and-policies/twitter-api#:~:text=TwitterのAPIプラットフォームは、プロバイダ利用者が世界で共有する設定にしている一般のTwitterデータへの広範囲でのアクセスを提供します。APIによってユーザーが非公開のTwitter情報（ダイレクトメッセージなど）を管理できるようにし、またこの情報を、許可した開発者に提供するようにもします。"
                target="_blank"
                rel="noreferrer"
              >
                TwitterAPIの詳しい説明はこちら
              </a>
              )
              <br />
            </p>
            <p className="qa-page__text">
              インスタントウィンなどのリツイートキャンペーンを実施する場合や、特定のキーワードに反応するBotアプリなどを作成し、アプリケーションを動作させるためにTwitterAPIを大量に呼び出す必要がある際は、Twitter社にAPIの上限解除を依頼する必要があります。
            </p>

            <p className="qa-page__text">
              <h2 className="qa-page__h2">TwitterのAPIについて</h2>
              TwitterのAPIプラットフォームは、プロバイダ利用者が世界で共有する設定にしている一般のTwitterデータへの広範囲でのアクセスを提供します。
              APIによってユーザーが非公開のTwitter情報（ダイレクトメッセージなど）を管理できるようにし、またこの情報を、許可した開発者に提供するようにもします。
            </p>

            <div className="qa-page__text">
              <h2 className="qa-page__h2">TwitterAPIで出来ること</h2>
              TwitterAPIを利用することで、Twitter上の様々なデータを自社のプログラムの一部として活用することができます。
              その中でも特にSNSキャンペーンやインスタントウィンに関係のありそうなものを、一部抜粋してご紹介します。
              <br />
              <br />
              <ol className="qa-page__ol">
                <li className="toc__item">ツイートを投稿する</li>
                <li className="toc__item">
                  指定したユーザーが自分のアカウントをフォローしているかチェックする
                </li>
                <li className="toc__item">
                  指定したツイートに「いいね」したユーザーの一覧を取得する
                </li>
                <li className="toc__item">
                  指定したツイートを「リツイート」したユーザーの一覧を取得する
                </li>
                <li className="toc__item">
                  指定したツイートを引用リツイートしたユーザーの一覧と、投稿されたテキストを取得する
                </li>
                <li className="toc__item">
                  メディア(静止画や動画)をアップロードする
                </li>
                <li className="toc__item">
                  指定したユーザーにリプライを送信する
                </li>
                <li className="toc__item">指定したユーザーにDMを送信する</li>
              </ol>
            </div>

            <div className="qa-page__text">
              <h2 className="qa-page__h2">TwitterAPIリミットについて</h2>
              TwitterAPIを利用する際は、システムの信頼性を維持することを目的に、アカウントの操作に関して以下のような一定の制限が定められています。
              <br />
              <br />
              <ol className="qa-page__ol">
                <li className="toc__item">
                  <a
                    href="https://help.twitter.com/ja/rules-and-policies/twitter-limits#:~:text=ツイート: 1日あたり2,400件が上限です"
                    target="_blank"
                    rel="noreferrer"
                  >
                    リプライであれば2400件/日
                  </a>
                </li>
                <li className="toc__item">
                  <a
                    href="https://help.twitter.com/ja/rules-and-policies/twitter-limits#:~:text=ダイレクトメッセージ（1日）: 1日に送れるメッセージは1,000件です"
                    target="_blank"
                    rel="noreferrer"
                  >
                    DMであれば1000件/日が上限です
                  </a>
                </li>
              </ol>
              <br />
              上記が代表的なAPI制限の例ですが、上記以外にも
              <a
                href="https://help.twitter.com/ja/rules-and-policies/twitter-limits"
                target="_blank"
                rel="noreferrer"
              >
                TwitterAPIには様々な制約
              </a>
              があります。
              これらは、PARKLoTのシステムの制限ではなく、Twitterプラットフォームの制限です。
              <br />
              一部、認証バッジを取得することで緩和される制限もあります。
            </div>
          </main>
          <Aside />
        </div>
      </Layout>
    </>
  )
}

export default HowTwitterInstantWinWorks
